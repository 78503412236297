{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": 0,
        "name": "Aign\u00e9"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1195,
          48.0649
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 1,
        "name": "Allonnes"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1606,
          47.9685
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 2,
        "name": "Arnage"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1891,
          47.9267
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 3,
        "name": "Champagn\u00e9"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.33,
          48.0215
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 4,
        "name": "Chaufour-Notre-Dame"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.0752,
          48.0255
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 5,
        "name": "Coulaines"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.2029,
          48.0237
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 6,
        "name": "Fatines"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.3475,
          48.0455
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 7,
        "name": "Fay"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.0701,
          48.0085
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 8,
        "name": "La Chapelle-Saint-Aubin"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1625,
          48.0338
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 9,
        "name": "La Milesse"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1368,
          48.0643
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 10,
        "name": "Le Mans"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1973,
          48.0075
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 11,
        "name": "Mulsanne"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.2459,
          47.9071
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 12,
        "name": "Pruill\u00e9-le-Ch\u00e9tif"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1079,
          47.9936
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 13,
        "name": "Rouillon"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1367,
          48.0048
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 14,
        "name": "Ruaudin"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.2636,
          47.9447
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 15,
        "name": "Saint-Georges-du-Bois"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.0998,
          47.9722
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 16,
        "name": "Saint-Saturnin"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.1594,
          48.0643
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 17,
        "name": "Sarg\u00e9-l\u00e8s-le-Mans"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.2405,
          48.0326
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 18,
        "name": "Trang\u00e9"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.115,
          48.0271
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 19,
        "name": "Yvr\u00e9-l'\u00c9v\u00eaque"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          0.2706,
          48.014
        ]
      }
    }
  ]
}